<template>
  <v-container fluid>
    <v-layout column fill-height wrap>
      <v-container
        v-if="!isLoading"
        style="width: 90%; max-width: 450px; max-height=90vh;"
        class="mx-auto"
      >
        <template v-if="contractReadStatus === ContractReadStatus.HasNotAccess">
          {{ $t('error.hasNotAccessError') }}
        </template>
        <template
          v-else-if="contractReadStatus === ContractReadStatus.HasClosed"
        >
          {{ $t('error.hasClosedError') }}
        </template>
        <template v-if="contractReadStatus === ContractReadStatus.Withdrawal">
          {{ $t('error.withdrawalError') }}
        </template>
        <template v-if="contractReadStatus === ContractReadStatus.Deleted">
          {{ $t('error.deletedError') }}
        </template>
        <template v-if="needsShowAgencyInfo">
          <v-row
            class="mt-2"
            v-for="agencyRecord in agencyRecords"
            :key="agencyRecord.key"
          >
            <v-col class="my-0 py-0">
              <p class="font-weight-bold mt-1">
                {{ $t(`header.agencyInfoErrorTable.${agencyRecord.key}`) }}
              </p>
            </v-col>
            <v-col class="my-0 py-0">
              <p class="mt-1">{{ agencyRecord.value }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="my-0 py-0">
              <v-btn
                style="font-size:16px"
                color="next"
                rounded
                outlined
                :href="'tel:' + agencyPhoneNumber.replace(/-/g, '')"
              >
                <v-icon class="mr-0" size="12px" left>phone</v-icon>
                {{ $t('button.agencyTel') }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
import { ContractReadStatus } from '@/lib/const';
import { Status } from '@/lib/status';
import { getContract } from '@/apis/contractor/contracts';

export default {
  name: 'ReadError',
  components: {},
  data() {
    return {
      // 案件閲覧ステータス種別
      ContractReadStatus: ContractReadStatus,

      // 案件閲覧ステータス
      contractReadStatus: null,

      // 代理店情報
      agencyRecords: [],

      // 代理店電話番号
      agencyPhoneNumber: '',

      // 代理店情報を表示する必要があるかどうか
      needsShowAgencyInfo: true,

      // ローディング中であるかどうか
      isLoading: true,
    };
  },
  async mounted() {
    // 案件情報の取得
    const contract = await getContract().catch(() => {});
    // 案件閲覧ステータスの取得
    const contractReadStatus = Status.getContractRead(
      contract.status,
      contract.startDate
    );

    // 案件閲覧ステータスが閲覧可である場合
    if (contractReadStatus === ContractReadStatus.Available) {
      // 利用規約／お手続きの流れ画面へ遷移して、以降の処理を中止する
      return this.$router.replace({ name: 'Tos' });
    }

    // 案件閲覧ステータスが閲覧不可でない場合
    if (contractReadStatus !== ContractReadStatus.HasNotAccess) {
      // 代理店情報をセットする
      this.$set(this, 'agencyRecords', this.mapAgencyRecords(contract));
      this.$set(this, 'needsShowAgencyInfo', true);
    } else {
    }

    // 代理店電話番号をセットする
    this.$set(this, 'agencyPhoneNumber', contract.agencyPhoneNumber);
    // 案件閲覧ステータスをセットする
    this.$set(this, 'contractReadStatus', contractReadStatus);

    this.$set(this, 'isLoading', false);
  },
  methods: {
    // 代理店情報のマッピング
    mapAgencyRecords(contract) {
      // 表示項目
      const keys = ['agencyName', 'chargePersonName'];

      return keys.map(key => ({
        key: key,
        value: contract[key],
      }));
    },
  },
};
</script>
