var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "fill-height": "", wrap: "" } },
        [
          !_vm.isLoading
            ? _c(
                "v-container",
                {
                  staticClass: "mx-auto",
                  staticStyle: { width: "90%", "max-width": "450px" }
                },
                [
                  _vm.contractReadStatus === _vm.ContractReadStatus.HasNotAccess
                    ? [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("error.hasNotAccessError")) +
                            "\n      "
                        )
                      ]
                    : _vm.contractReadStatus ===
                      _vm.ContractReadStatus.HasClosed
                    ? [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("error.hasClosedError")) +
                            "\n      "
                        )
                      ]
                    : _vm._e(),
                  _vm.contractReadStatus === _vm.ContractReadStatus.Withdrawal
                    ? [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("error.withdrawalError")) +
                            "\n      "
                        )
                      ]
                    : _vm._e(),
                  _vm.contractReadStatus === _vm.ContractReadStatus.Deleted
                    ? [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("error.deletedError")) +
                            "\n      "
                        )
                      ]
                    : _vm._e(),
                  _vm.needsShowAgencyInfo
                    ? [
                        _vm._l(_vm.agencyRecords, function(agencyRecord) {
                          return _c(
                            "v-row",
                            { key: agencyRecord.key, staticClass: "mt-2" },
                            [
                              _c("v-col", { staticClass: "my-0 py-0" }, [
                                _c(
                                  "p",
                                  { staticClass: "font-weight-bold mt-1" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "header.agencyInfoErrorTable." +
                                              agencyRecord.key
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]),
                              _c("v-col", { staticClass: "my-0 py-0" }, [
                                _c("p", { staticClass: "mt-1" }, [
                                  _vm._v(_vm._s(agencyRecord.value))
                                ])
                              ])
                            ],
                            1
                          )
                        }),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { staticClass: "my-0 py-0" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticStyle: { "font-size": "16px" },
                                    attrs: {
                                      color: "next",
                                      rounded: "",
                                      outlined: "",
                                      href:
                                        "tel:" +
                                        _vm.agencyPhoneNumber.replace(/-/g, "")
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-0",
                                        attrs: { size: "12px", left: "" }
                                      },
                                      [_vm._v("phone")]
                                    ),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("button.agencyTel")) +
                                        "\n            "
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }